<template>
  <div class="home-contianer">
    <el-scrollbar style="height:100%;">
      <div style>
        <div data-v-542f4644 class="ivu-row">
          <div v-for="item in topColor" :key="item.name" class="ivu-col ivu-col-span-6">
            <div class="item-name">
              {{ item.name }}
              <Tooltip class="icon" placement="left-start">
                <Icon type="ios-information-circle-outline" />
                <div slot="content">
                  <p>{{ item.name }}</p>
                </div>
              </Tooltip>
            </div>
            <div class="total">{{ item.total }}</div>

          </div>
        </div>
        <div class="charts-line">
          <div id="charts-line" class="left" style="height:350px;"></div>

        </div>

      </div>
    </el-scrollbar>
  </div>
</template>
<script>
import { gethomeStatisticsRequest } from '@/api/home'
import * as echarts from 'echarts'
export default {
  data() {
    return {
      n: 90,
      cell: [],
      topColor: [],
      value1: '1',
      xAxisdata: [],
      yAxisdata: [],
    }
  },
  created() {
    this.gethomeStatistics()
  },

  mounted() { },
  methods: {
    gethomeStatistics() {
      gethomeStatisticsRequest({}).then((res) => {
        this.topColor = res.data.list
        this.cell = res.data.listproduct

        this.xAxisdata = res.data.xAxisdata
        this.yAxisdata = res.data.yAxisdata
        this.getec()
      })
    },

    getec() {
      var $charts_line = echarts.init(document.getElementById('charts-line'))
      $charts_line.setOption({
        title: {
          text: '近七日交易走势',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            },
          },
        },

        color: ['#ffab6f', '#09b916', '#83cddc'], //图例颜色
        legend: {
          icon: 'roundRect',
          data: ['销售订单'],
        },
        toolbox: {
          feature: {},
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: this.xAxisdata,
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {
              lineStyle: {
                color: '#eee',
              },
            },
          },
        ],
        series: [
          {
            name: '销售订单',
            type: 'line',
            smooth: true,
            lineStyle: {
              color: '#45d4ba',
              width: 1,
            }, //线条的样式
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#83cddc',
                },
                {
                  offset: 1,
                  color: '#bfdffbb5',
                },
              ]),
            },
            data: this.yAxisdata,
          },
        ],
      })
    },
  },
}
</script> 

<style lang="less" scoped>
.home-contianer {
  background: #efefef;
  width: 100%;
  height: 100%;
  /* padding: 20px; */
}

.home-app {
  display: inline-block;
  /* display: -webkit-flex;
  display: flex; */
  padding: 15px;
  padding-top: 5px;
}

.home-app>div {
  float: left;
  width: 33.33333%;
  padding-left: 8px;
  padding-right: 8px;
}

.ivu-card-body {
  text-align: center;
  padding: 25px 13px;
  padding-left: 80px;
}

.demo-color-name {
  color: #fff;
  font-size: 16px;
}

.demo-color-desc {
  color: #fff;
  opacity: 0.7;
}

.ivu-card {
  position: relative;
}

.ivu-card .icon-left {
  border-right: 1px solid;
  padding: 10px 24px;
  height: 100%;
  position: absolute;
  font-size: 50px;
  color: white;
}

.ivu-row {
  display: flex;
  border-bottom: 2px dotted #eee;
  padding: 0px 5px;

  .ivu-col {
    border-radius: 4px;
    background: white;
    flex: 1;
    margin: 13px 8px;
    padding: 20px 20px;
    padding-bottom: 15px;
  }

  .total {
    word-break: break-all;
    color: #282727;
    font-size: 30px;
    padding-bottom: 12px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB,
      Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol;
  }

  .item-name {
    position: relative;

    .icon {
      position: absolute;
      right: 0;
      top: -1px;
    }
  }

  .item-name,
  .rate,
  .bottom {
    color: #5f5f5f;
    font-size: 14px;
  }

  .rate {
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 10px;
  }

  .bottom {
    padding-top: 8px;
  }

  .down {
    color: #10d310;
  }

  .up {
    color: red;
  }
}

.h5-desc {
  padding-top: 10px;
}
</style>
<style lang="less" scope>
.charts-line {
  margin: 0px 13px 13px 13px;
  display: flex;

  .left {
    flex: 1;
    background: white;
    padding: 10px;
  }

  .right {
    padding: 10px 15px;
    background: white;
    height: 350px;
    width: 400px;
    margin-left: 16px;

    .badge-count {
      padding: 3px 7px;
      position: relative;
      border: 1px solid #eee;
      border-radius: 50%;

      margin-right: 11px;
    }

    .badge {
      background: #e2e2e2;
      color: #3a3535;
    }

    .top3 {
      background: #2db7f5;
      color: white;
    }

    .cell {
      position: relative;
      display: flex;
      padding: 12px 0;
      border-bottom: 1px dotted #eee;
    }

    .primary {
      flex: 1;
    }

    .title {
      font-size: 16px;
      padding-bottom: 6px;
      border-bottom: 1px solid #eee;
      margin-bottom: 11px;
    }

    .name {
      font-size: 15px;
      position: relative;
      top: 5px;
      color: #303133;
      left: 12px;
    }

    .desc {
      margin-left: 27px;
      font-size: 12px;
      color: #b3b3b3;
      position: relative;
      top: 5px;
    }
  }
}
</style>